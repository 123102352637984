import contentLoaded from './vendor/contentloaded.min.js'
import './vendor/jquery.viewportchecker.min.js'
import validate from './vendor/jquery.validate.min.js'
import accordion from './accordion.js'
import images from './images.js'
// import multistep from './multi-step-form.js'
import phoneConcat from './phone-concatination.js'
import polyfill from './polyfills.js'
import sliders from './sliders.js'
import smoothScroll from './smooth-scroll.js'
import stickyHeader from './sticky-header.js'
import timer from './timer.js'
import videos from './video-embeds.js'
import viewAnimation from './view-animation.js'
import getCurrentMonth from './month.js'
import kkThankYou from './thank-you.js';

contentLoaded()

//easing()
//validate()

window.contentLoaded(window, function (e) {
	polyfill()
	kkThankYou();
	phoneConcat()
	images()
	getCurrentMonth()
	sliders()
	smoothScroll()
	stickyHeader()
	videos()
	viewAnimation()
	getCurrentMonth()

	//accordion()
	//timer()
	//multistep();
})
